/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/css/bourbon/bourbon";
@import "./assets/css/mixins/colors";
@import "./assets/css/mixins/mini-bootstrap";
@import "./assets/css/mixins/mixins";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/css/fonts.css";
@import "./my-theme.scss";
@import "~mapbox-gl/dist/mapbox-gl.css";

body {
  margin: 0;
}
* {
  box-sizing: border-box;
}

.ngx-timepicker-control__arrows {
  display: none !important;
}
.period-control__arrow {
  display: none !important;
}
.period-control__button {
  width: fit-content !important;
}
.period-control__button::after {
  background: #2a6af5 !important;
  height: 2px !important;
}
.ngx-timepicker-control {
  width: 40px !important;
}
.ngx-timepicker-control--active::after {
  background: #2a6af5 !important;
  height: 2px !important;
}
.period-selector {
  width: fit-content !important;
  right: 0px !important;
  top: 25px !important;
  padding: 0px !important;
}
.ngx-timepicker__time-colon {
  margin: 0px !important;
}
.ngx-timepicker {
  border: none !important;
}
.period-selector__button {
  height: fit-content !important;
  line-height: normal !important;
  vertical-align: middle !important;
  padding: 0 !important;
  text-align: center !important;
}
.period-selector__button--active {
  color: #2a6af5 !important;
}
.ngx-timepicker-control__input {
  padding: 0px !important;
}

.scroll-thick-style::-webkit-scrollbar {
  height: 12px;
}
.scroll-thick-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.scroll-thick-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 50px #eaecf0;
}

/* Scroll bar */
.scroll-thin-style::-webkit-scrollbar {
  height: 12px;
  width: 5px;
}
.scroll-thin-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #eaecf0;
  border-radius: 10px;
}
.scroll-thin-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.3);
}
.table_wraper {
  flex-grow: 1;
  box-sizing: border-box;
  margin: 10px;
  border-radius: 12px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  overflow: hidden;
}
.controls {
  margin: 10px;
  display: flex;
  align-items: center;
  app-button {
    width: 200px;
  }
  app-form-input {
    flex-grow: 1;
  }
}

.custom-blue-solid-btn {
  @apply w-full flex px-[18px] py-[10px] justify-center items-center rounded-lg border border-solid border-[#2a6af6] bg-[#2a6af6] text-white font-Inter text-base font-semibold;
}
.custom-outlined-btn {
  @apply w-full flex px-[18px] py-[10px] justify-center items-center rounded-lg border border-solid border-[#d0d5dd] bg-white text-[#344054] font-Inter text-base font-semibold;
}

p a {
  color: #2a6af6;
  text-decoration: underline;
}

.non-touchable {
  pointer-events: none;
}
